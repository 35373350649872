export const environment = {
  production: false,
};

export const apiQBonoEnvironment = {
  baseUrl: 'https://api.qaroni.net/navalmoral/v1',
};

export const GoogleMapsEnvironment = {
  key: 'AIzaSyDywlSyhXUpAGC2oOI0TGw8T46XWrkkNCU',
  faqUrl: '/#/dashboard/support?faqId=32',
};
